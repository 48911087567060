<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Broadcast <span>Rename</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @submit="handleSubmit" v-slot="{ errors }" ref="rename-form-form">
                            <div class="group_item">
                                <label class="input_label">Email Subject
                                    <emoji-picker :onSelectEmoji="insertEmoji" classes="subject-emoji-picker" ref="emoji-rename-broadcast" v-click-outside="hideEmoji" />
                                    <div class="dropdown" @click="personalizeOption = !personalizeOption">
                                        <i class="fas fa-user-alt"></i>
                                        <div class="drp_wrapper" v-if="personalizeOption" :class="{active : personalizeOption}">
                                            <ul>
                                                <li v-for="(personalize, p) in defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </label>
                                <div class="field_wpr" :class="{ 'has-error': errors.subject }">
                                    <Field autocomplete="off" type="text" name="subject" id="broadcast_subject" v-model="form.subject" placeholder="Type Subject" rules="required" />
                                </div>
                                <ErrorMessage name="subject" class="text-danger" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))

    import constants from '@/utils/Constant'

    export default {
        name: 'Rename Form',

        data () {
            return {
                form: {
                    subject: '',
                    id: '',
                },
                personalizeOption: false,
                defaultPersonalize: [],
            }
        },

        props: {
            modelValue: Boolean,
            broadcast: Object,
            refreshBroadcast: {
                type: Function,
                default: () => {},
            }
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            EmojiPicker
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        subject: vm.broadcast.subject,
                        id: vm.broadcast.id,
                    },
                    vm.defaultPersonalize = constants.personalize;
                }
            }
        },

        computed: mapState({
            loader: state => state.broadcastModule.broadcastComponentLoader,
        }),

        methods: {
            ...mapActions({
                renameBroadcast: 'broadcastModule/renameBroadcast'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['rename-form-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenameBroadcast(form);
                    }
                });
            },

            handleRenameBroadcast (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.renameBroadcast(vm.form).then((result) => {
                    if (result) {
                        vm.refreshBroadcast();
                        vm.closeModal();
                    }
                });
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById('broadcast_subject');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.form.subject = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById('broadcast_subject');
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.form.subject = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            hideEmoji () {
                const vm = this;
                const emoji = vm.$refs['emoji-rename-broadcast'];

                if (emoji && emoji.toggle) {
                    emoji.toggle = false;
                }
            },
        }
    }
</script>
<style scoped>
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
    }

    :deep(.emoji-picker.subject-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker) {
        position: absolute;
        right: 0;
    }

    :deep(.subject-emoji-picker .dropdown-toggle) {
        margin-right: 40px;
    }

    .input_label {
        position: relative;
        z-index: 3;
    }
</style>
